import React from "react";
import Helmet from "react-helmet";
import {
  Box,
  Text,
  Image,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  useBreakpointValue,
  Link,
} from "@chakra-ui/react";
import Particles from "react-particles-js";
import GlobalStyles from "../styles/global";

// Assets
import LaceBanner from "../components/LaceBanner";
import Footer from "../components/footer";
import Chrome from "../images/chrome.png";
import Brave from "../images/brave.png";
import Edge from "../images/edge.png";
import Github from "../images/github.png";
import Twitter from "../images/twitter.svg";
import Banner from "../images/icon.svg";

import { AnalyticsProvider } from "../analytics/AnalyticsContext";

const IndexPage = () => {
  const value = useBreakpointValue({ base: "240px", lg: "80px" });

  const logoSize = useBreakpointValue({
    base: "40px",
    xl: "50px",
  });
  const fontSize = useBreakpointValue({
    base: "14px",
    sm: "14px",
    md: "14px",
    lg: "14px",
    xl: "18px",
  });
  const padding = useBreakpointValue({
    base: "96",
    lg: "60px",
  });
  return (
    <>
      <AnalyticsProvider>
        <GlobalStyles />
        <LaceBanner />
        <Helmet title="Nami" />
        <Box
          position="relative"
          width="full"
          minHeight="100vh"
          rounded="sm"
          display="flex"
          alignItems="center"
          flexDirection="column"
          overflow="hidden"
          paddingTop={50}
        >
          <NamiParticles />
          <Box h="10" />
          <Image width="170px" src={Banner} />
          <Box h="10" />
          <Text fontSize="18">Nami is available on</Text>
          <Box h="5" />
          <Box display="flex" alignItems="center" justifyContent="center">
            <Box
              onClick={() =>
                window.open(
                  "https://chrome.google.com/webstore/detail/nami-wallet/lpfcbjknijpeeillifnkikgncikgfhdo?hl"
                )
              }
              cursor="pointer"
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
            >
              <Image draggable={false} src={Chrome} height="40px" />
              <Box h="3" />
              <Text fontSize="xs">Chrome</Text>
            </Box>
            <Box w="10" />
            <Box
              onClick={() =>
                window.open(
                  "https://chrome.google.com/webstore/detail/nami-wallet/lpfcbjknijpeeillifnkikgncikgfhdo?hl"
                )
              }
              cursor="pointer"
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
            >
              <Image draggable={false} src={Brave} height="40px" />
              <Box h="3" />
              <Text fontSize="xs">Brave</Text>
            </Box>
            {/* <Box
              onClick={() =>
                window.open(
                  "https://chrome.google.com/webstore/detail/nami-wallet/lpfcbjknijpeeillifnkikgncikgfhdo?hl"
                )
              }
              cursor="pointer"
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
            >
              <Image draggable={false} src={Edge} height="40px" />
              <Box h="3" />
              <Text fontSize="xs">Edge</Text>
            </Box> */}
          </Box>
          <Box h="3" />
          <Text fontSize="11">and any other chrome-based browser</Text>
          <Box h="6" />
          <Text>Source Code</Text>
          <Box h="2" />
          <Image
            draggable={false}
            cursor="pointer"
            width="30px"
            src={Github}
            onClick={() =>
              window.open("https://github.com/Berry-Pool/nami-wallet")
            }
          />
          <Box h="6" />
          <Text>Social</Text>
          <Box h="2" />
          <Image
            draggable={false}
            cursor="pointer"
            width="26px"
            src={Twitter}
            onClick={() => window.open("https://twitter.com/NamiWallet")}
          />
          <Box h="10" />
          <Text fontSize="18" fontWeight="bold">
            FAQ
          </Text>
          <Box h="4" />
          <Accordion
            allowMultiple
            rounded="md"
            zIndex="1"
            width="90%"
            maxWidth="800px"
            background="white"
            padding="4"
          >
            <AccordionItem>
              <h2>
                <AccordionButton rounded="md">
                  <Box flex="1" textAlign="left">
                    What is Nami?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                Nami is a non-custodial, browser-based wallet extension for
                interacting with the Cardano blockchain and connecting with
                DApps. It allows users to send and store multiple assets,
                delegate ada, and more.
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <h2>
                <AccordionButton rounded="md">
                  <Box flex="1" textAlign="left">
                    Is Nami compatible with other Cardano wallets?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                Nami is fully compatible with Lace, but has limited
                compatibility with others. It does not track all addresses from
                an imported wallet which leads to only partial asset reflection.
                Nami uses a single address approach and allows users to create
                sub-accounts from a single recovery phrase. Migration between
                wallets is possible, but creating a new wallet for Nami is
                recommended.
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <h2>
                <AccordionButton rounded="md">
                  <Box flex="1" textAlign="left">
                    Is there a cost for using Nami?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                Nami is free to download and use. Some third-party services may
                charge fees.
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <h2>
                <AccordionButton rounded="md">
                  <Box flex="1" textAlign="left">
                    Can I import multiple wallets?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                Nami allows users to import one recovery phrase and pair
                multiple hardware wallets. Each wallet has multiple accounts,
                each functioning independently with a degree of privacy and
                anonymity. It supports delegation to different pools from each
                account.
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <h2>
                <AccordionButton rounded="md">
                  <Box flex="1" textAlign="left">
                    Can I stake/delegate from Nami?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                Nami supports delegation to any Cardano stake pool.
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <h2>
                <AccordionButton rounded="md">
                  <Box flex="1" textAlign="left">
                    What is Collateral?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                In Cardano, phase one validation for transactions ensures
                verification before network entry. Smart contracts are validated
                in phase two, with collateral taken for resource usage in case
                of contract failure. Nami limits collateral to five ada to
                minimize risks and prevent network spamming.
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <h2>
                <AccordionButton rounded="md">
                  <Box flex="1" textAlign="left">
                    What is my private key?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                A private key is a long sequence of digits and letters that
                uniquely verify ownership of your digital assets. Private keys
                should only ever be shared with the key's generator or parties
                authorized to decrypt the data.
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <h2>
                <AccordionButton rounded="md">
                  <Box flex="1" textAlign="left">
                    What is my recovery phrase?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                Because the private key can be hard to remember, you’ll also get
                a recovery phrase (also known as a mnemonic or a seed phrase) of
                24 words when you first create your wallet. Typically, the
                longer the word sequence, the less likely someone will be able
                to guess your recovery phrase.
                <br />
                <br />
                You use the phrase to recover your wallet and access your
                assets, so make sure you store it securely. If you are
                recovering your wallet from another platform on Cardano, you may
                have a 12 or 15-word recovery phrase.
                <br />
                <br />
                You will receive your recovery phrase when you first create your
                wallet. Make sure you keep it in a safe place, preferably
                written down somewhere offline. Please note that neither IOG nor
                its affiliates will know or store your recovery phrase. If you
                lose it, there is no way to recover it and access your digital
                assets.
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <h2>
                <AccordionButton rounded="md">
                  <Box flex="1" textAlign="left">
                    What happens if I forget my wallet password?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                If you forget your password, you can only recover your wallet by
                using your recovery phrase. So make sure you always store your
                recovery phrase securely offline – write it down and keep it in
                a secure location.
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <h2>
                <AccordionButton rounded="md">
                  <Box flex="1" textAlign="left">
                    What happens if I lose my recovery phrase?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                If you lose your recovery phrase, you lose access to your wallet
                and your digital assets. That’s why it’s so important that you
                store it somewhere safe.
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <h2>
                <AccordionButton rounded="md">
                  <Box flex="1" textAlign="left">
                    Is there a way to recover my wallet if I lose my recovery
                    phrase?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                No. There’s really no way to recover your wallet if you lose
                your recovery phrase. This is fundamental to how the blockchain
                works. You and only you are the owner of your recovery phrase
                and that is why losing your recovery phrase means losing access
                to your digital assets. Neither IOG nor its affiliates will know
                or can restore your recovery phrase, and there's nothing that
                can be done to help you if you lose it. That’s why it’s of
                critical importance that you save your recovery phrase in a safe
                place.
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <h2>
                <AccordionButton rounded="md">
                  <Box flex="1" textAlign="left">
                    How do I add ada to Nami?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                You first need to purchase ada from an exchange and send it to
                your wallet address – you can find your wallet address by
                clicking the Receive button.
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <h2>
                <AccordionButton rounded="md">
                  <Box flex="1" textAlign="left">
                    Can I send ada from Nami to other wallets?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                You can send ada to any Cardano-compatible wallet. Nami includes
                safeguards to prevent you from sending digital assets to wallets
                that don’t support Cardano, but it's always a good idea to
                triple check the address you are sending your digital assets to.
                A good rule of thumb is that all Cardano addresses start with
                ‘addr1__’.
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <h2>
                <AccordionButton rounded="md">
                  <Box flex="1" textAlign="left">
                    What is network switching?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                Nami lets you switch between networks to test new releases and
                features, before they’re deployed on the mainnet. You can choose
                from three testing environments (preview, pre production and the
                deprecated testnet) and the mainnet environment (production).
                <br />
                <br />
                Network switching means there are fewer bugs on the mainnet and
                means everyone enjoys a smoother experience.
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <h2>
                <AccordionButton rounded="md">
                  <Box flex="1" textAlign="left">
                    Why does Nami ask to read your Chrome browsing history?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                Upon installing the Nami extension on Chrome, you would have
                encountered a notification asking for your consent to access
                your browsing history. By clicking “I accept” you allow us to
                use cookies to collect information about your use of our
                services. The information we collect is anonymous and is used to
                understand how you interact with our services, like when and how
                often you use them, what links you visit and from where. This
                information helps us detect issues, understand where we may
                improve, or develop new products or services. As an example,
                should a decentralized application (DApp) initiate a link with
                Nami through the CIP-30 - Cardano DApp-Wallet Web Bridge, Nami
                will utilize the Chrome Tabs API to gather details such as the
                DApp's URL, name, and icon. This process allows these details to
                be accurately reflected within the Nami interface.
                <br />
                <br />
                We are committed to giving you privacy options to control or
                limit our ability to use cookies and to collect cookie related
                data. Please visit Settings {">>"} Legal {">>"} Analytics on
                your browser to adjust your cookie settings.
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
          <Box pb={padding} />
        </Box>
        <Footer />
      </AnalyticsProvider>
    </>
  );
};
const NamiParticles = () => (
  <Particles
    params={{
      particles: {
        number: {
          value: 30,
          density: {
            enable: false,
          },
        },
        color: {
          value: "#349EA3",
        },
        size: {
          value: 4,
          random: true,
          anim: {
            speed: 2,
            size_min: 0.3,
          },
        },
        line_linked: {
          enable: false,
        },
        move: {
          random: true,
          speed: 1,
          direction: "top",
          out_mode: "out",
        },
      },
      interactivity: {
        events: {
          onclick: {
            enable: true,
            mode: "repulse",
          },
        },
        modes: {
          bubble: {
            distance: 250,
            duration: 4,
            size: 0,
            opacity: 0,
          },
          repulse: {
            distance: 200,
            duration: 2,
          },
        },
      },
    }}
    style={{
      zIndex: -1,
      position: "absolute",
      left: 0,
      top: 0,
      height: "100%",
      width: "100%",
    }}
  />
);

export default IndexPage;
