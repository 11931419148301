import { createGlobalStyle } from "styled-components";
import { osanoStyling, osanoVariables } from "./osano-dialog";

export default createGlobalStyle`
  ${osanoStyling}
  
  :root {
    ${osanoVariables}
  }
`;
