import React, { useState } from "react";
import styled from "styled-components";
import Emphasize from "../utils/Emphasize";
import CloseBanner from "./icons/CloseBanner";
import { pageStyles } from "../utils/styles";

const Container = styled.div`
  height: 50px;
  background-color: #ff87d9;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
`;

const Contents = styled.div`
  max-width: 1520px;
  width: 100%;
  padding-left: 40px;
  padding-right: 40px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @media ${"(max-width: 899px)"} {
    flex-direction: column;
    justify-content: center;
  }

  @media ${"(max-width: 360px)"} {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 100%;
  > * + * {
    margin-left: 30px;
  }
`;

const BannerHeading = styled.h3`
  color: #ffffff;
  font-family: ${pageStyles.fontFamily};
  font-weight: 700;
  font-size: 16px;
  line-height: ${42 / 30};
  @media ${"(max-width: 899px)"} {
    text-align: center;
  }
  @media ${"(max-width: 599px)"} {
    font-size: 15px;
  }

  @media ${"(max-width: 360px)"} {
    font-size: 14px;
  }
`;

const BannerLink = styled.span`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-weight: 600;
  font-size: 14px;
  text-decoration: underline;
  color: #ffffff;
  padding-right: 65px;
  @media ${"(max-width: 899px)"} {
    padding-right: 0;
  }
`;

const CloseButton = styled.button`
  color: #ffffff;
  right: 5px;
  @media ${"(max-width: 899px)"} {
    position: absolute;
    top: 5px;
    right: 10px;
  }
`;

const LaceBanner = () => {
  const [bannerShowing, setBannerShowing] = useState(true);
  return (
    bannerShowing && (
      <Container>
        <Contents>
          <Column>
            <BannerHeading>
              Nami has <Emphasize>joined</Emphasize> the IOG product family
            </BannerHeading>
          </Column>
          <Column>
            <BannerLink>
              <a href="https://www.lace.io/blog/lace-says-hello-to-nami?utm_source=lace.io&utm_medium=website&utm_campaign=nami&utm_content=banner">
                Learn more
              </a>
            </BannerLink>
            <CloseButton onClick={() => setBannerShowing(false)}>
              <CloseBanner />
            </CloseButton>
          </Column>
        </Contents>
      </Container>
    )
  );
};

export default LaceBanner;
