import React from "react";
import styled from "styled-components";
import IOLogo from "../images/ioLogo.svg";
import { Image } from "@chakra-ui/react";
import { pageStyles } from "../utils/styles";

const FooterWrapper = styled.footer`
  background-color: #000000;
  padding-top: 62px;
  padding-left: 20px;
  padding-right: 20px;

  @media ${"(max-width: 990px)"} {
    //Negative margins to account for the resizing boxes that come built in from the chakra-ui used on the index page boxes
    margin-top: -320px;

    padding-left: 40px;
    padding-right: 40px;
  }
`;

const FooterContent = styled.div`
  width: 100%;
  max-width: 1436px;
  margin: 0 auto;
  padding-bottom: 110px;
  padding-top: 45px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  border-top: 1px solid #515151;
  padding-right: 140px;

  @media ${"(max-width: 899px)"} {
    flex-direction: column;
  }
`;

const LogoHolder = styled.div``;

const NavHolder = styled.div`
  & a,
  & a:link,
  & a:visited {
    display: block;
    color: #afafaf;
    text-decoration: none;

    font-family: ${pageStyles.fontFamily};
    &:hover {
      text-decoration: underline;
    }
  }
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const NavWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  gap: 130px;

  @media ${"(max-width: 899px)"} {
    flex-direction: column;
    padding-top: 60px;
    gap: 50px;
  }
`;

const NavTitle = styled.span`
  font-size: 16px;
  line-height: 18px;
  font-weight: 500;
  color: #ffffff;
  font-family: ${pageStyles.fontFamily};
  padding-bottom: 5px;
`;

const Copyright = styled.div`
  position: absolute;
  bottom: 40px;
  margin-left: 140px;
  transform: translateX(-50%);
  font-size: 13px;
  font-family: ${pageStyles.fontFamily};
  color: #afafaf;

  @media ${"(max-width: 899px)"} {
    bottom: 20px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    transform: none;
    text-align: center;
  }

  @media ${"(max-width: 599px)"} {
    width: 80%;
  }
`;

const Footer = () => {
  return (
    <FooterWrapper>
      <FooterContent>
        <LogoHolder data-logo-holder>
          <Image draggable={false} src={IOLogo} width={217} />
        </LogoHolder>
        <NavWrapper>
          <NavHolder>
            <NavTitle>Resources</NavTitle>
            <a target="_blank" href="https://iohk.io/" rel="noreferrer">
              Input Output Global
            </a>
            <a target="_blank" href=" http://lace.io" rel="noreferrer">
              Lace
            </a>
            <a target="_blank" href="https://cardano.org/" rel="noreferrer">
              Cardano.org
            </a>
            <a
              target="_blank"
              href="https://essentialcardano.io/"
              rel="noreferrer"
            >
              Essential Cardano
            </a>
            <a
              target="_blank"
              href="https://www.youtube.com/channel/UCX9j__vYOJu00iqBrCzecVw"
              rel="noreferrer"
            >
              IOG Academy
            </a>
          </NavHolder>
          <NavHolder>
            <NavTitle>Legal</NavTitle>
            <a target="_blank" href="/iog-cookie-policy.pdf" rel="noreferrer">
              Cookie Policy
            </a>
            <a target="_blank" href="/iog-privacy-policy.pdf" rel="noreferrer">
              Privacy Policy
            </a>
            <a
              target="_blank"
              href="/iohktermsandconditions.pdf"
              rel="noreferrer"
            >
              Terms of Use
            </a>
          </NavHolder>
        </NavWrapper>
        <Copyright>
          © {new Date().getFullYear()} Input Output Global. All Rights Reserved
        </Copyright>
      </FooterContent>
    </FooterWrapper>
  );
};
export default Footer;
