import * as React from "react";
const CloseBanner = (props) => (
  <svg
    width={13}
    height={11}
    viewBox="0 0 13 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.794 3.22002C12.5179 2.56374 12.5179 1.48981 11.794 0.83353C11.0701 0.177245 9.88553 0.177245 9.16163 0.83353L6.5293 3.22002L3.89696 0.83353C3.17307 0.177245 1.98852 0.177245 1.26462 0.83353C0.54073 1.48981 0.54073 2.56374 1.26462 3.22002L3.89696 5.60651L1.26462 7.993C0.54073 8.64929 0.54073 9.72321 1.26462 10.3795C1.98852 11.0358 3.17307 11.0358 3.89696 10.3795L6.5293 7.993L9.16163 10.3795C9.88553 11.0358 11.0701 11.0358 11.794 10.3795C12.5179 9.72321 12.5179 8.64929 11.794 7.993L9.16163 5.60651L11.794 3.22002Z"
      fill="white"
    />
  </svg>
);

export default CloseBanner;
